import { BackupHero } from '@components/homepage/backup_hero/backup_hero';
import { LazyLoadedWidgetsProps } from '@components/homepage/lazy_loaded_widgets';
import { PhoneCaptureModal } from '@components/homepage/phone_capture_modal';
import { PressBlockWidget } from '@components/homepage/press_block_widget/press_block_widget';
import { ServiceAreaWidget } from '@components/homepage/service_area_widget';
import { ValuePropBar } from '@components/homepage/value_prop_bar';
import { HeroWidget } from '@shared_components/butter_cms/hero_widget';
import { ButterCMSService } from '@services/butter_cms_service';
import { ButterHomepage } from '@services/butter_cms_service/home_page_response.interface';
import { SentryService } from '@services/sentry_service';
import { NavAndFooter } from '@shared_components/layouts/nav_and_footer';
import { GetServerSideProps, GetStaticPaths, NextPage } from 'next';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Fragment } from 'react';
import { getBrandProps } from '@utility_functions/getBrandProps';

const { publicRuntimeConfig } = getConfig();

const LazyLoadedWidgets = dynamic<LazyLoadedWidgetsProps>(
  () =>
    import('@components/homepage/lazy_loaded_widgets').then(
      (module) => module.LazyLoadedWidgets
    ),
  {
    ssr: false,
  }
);

const Homepage: NextPage<{
  homepageData: ButterHomepage | null;
}> = ({ homepageData }) => {
  const heroData = homepageData ? homepageData.fields.hero : null;
  const title = homepageData ? homepageData.fields.seo.title : null;
  const description = homepageData ? homepageData.fields.seo.description : null;

  return (
    <NavAndFooter>
      <Head>
        {title && (
          <Fragment>
            <title key="title">{title}</title>
            <meta property="og:title" key="og:title" content={title} />
          </Fragment>
        )}

        {description && (
          <Fragment>
            <meta name="description" key="description" content={description} />
            <meta
              property="og:description"
              key="og:description"
              content={description}
            />
          </Fragment>
        )}

        {homepageData && (
          <Fragment>
            <meta
              property="og:image"
              key="og:image"
              content={homepageData.fields.hero.image}
            />
          </Fragment>
        )}
      </Head>
      <PhoneCaptureModal />
      <div className="mb-90">
        {heroData ? (
          <HeroWidget
            headline={heroData.headline}
            subheadline={heroData.subheadline}
            text_color={heroData.text_color}
            background_color={heroData.background_color}
            image={heroData.image}
            button_label={heroData.button_label}
            button_url={heroData.button_url}
            button_size={heroData.button_size}
            button_color={heroData.button_color}
          />
        ) : (
          <BackupHero />
        )}
        <ValuePropBar />
        <ServiceAreaWidget />
        <PressBlockWidget />
        <LazyLoadedWidgets />
      </div>
    </NavAndFooter>
  );
};

export default Homepage;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { res } = context;

  const { props } = await getBrandProps(context);

  if (publicRuntimeConfig.appName === 'Production') {
    // Had trouble using getStaticProps so adding this cache-control header to help a little with cacheing the response here. This will only work for a specific user as they need the header set.
    // If we see the need to do more cacheing we can add cacheing on the cloudfront layer. Cloudfront can cache the response for all users in a region.
    res.setHeader(
      'Cache-Control',
      // 43200 = 12 hours
      // 21600 = 6 hours
      `public, max-age=43200, stale-while-revalidate=21600`
    );
  }

  if (!props.brand) {
    return {
      props: {
        homepageData: null,
      },
    };
  }

  const cmsRes = await ButterCMSService.getHomePage(props.brand.name).catch(
    (err) => {
      SentryService.captureError(new Error('Homepage get static props'), {
        err,
      });
    }
  );

  return {
    props: {
      ...props,
      homepageData: cmsRes?.data ?? null,
    },
  };
};
