import { KitchenSinkHeader } from '@components/kitchen_sink/kitchen_sink_header';
import React, { FunctionComponent } from 'react';
import { FernImage } from './fern-image';

export const FernImageStory: FunctionComponent<{}> = () => {
  return (
    <div>
      <KitchenSinkHeader title={'Fern-Image'} />

      <div className="grid grid-cols-5 gap-x-10">
        <div>
          <FernImage
            className="fern-image--1x1"
            imageUrl="https://placekitten.com/640/640"
            altText="alt text goes here"
          ></FernImage>
        </div>
        <div>
          <FernImage
            className="fern-image--3x2"
            imageUrl="https://placekitten.com/640/640"
            altText="alt text goes here"
          ></FernImage>
        </div>
        <div>
          <FernImage
            className="fern-image--2x3"
            imageUrl="https://placekitten.com/640/640"
            altText="alt text goes here"
          ></FernImage>
        </div>
        <div>
          <FernImage
            className="fern-image--3x4"
            imageUrl="https://placekitten.com/640/640"
            altText="alt text goes here"
          ></FernImage>
        </div>
        <div>
          <FernImage
            className="fern-image--4x3"
            imageUrl="https://placekitten.com/640/640"
            altText="alt text goes here"
          ></FernImage>
        </div>
      </div>

      <div>Missing Images</div>
      <div className="grid grid-cols-5 gap-x-10">
        <div>
          <FernImage
            className="fern-image--1x1"
            imageUrl={null}
            altText="alt text goes here"
          ></FernImage>
        </div>
        <div>
          <FernImage
            className="fern-image--3x2"
            imageUrl={null}
            altText="alt text goes here"
          ></FernImage>
        </div>
        <div>
          <FernImage
            className="fern-image--2x3"
            imageUrl={null}
            altText="alt text goes here"
          ></FernImage>
        </div>
        <div>
          <FernImage
            className="fern-image--3x4"
            imageUrl={null}
            altText="alt text goes here"
          ></FernImage>
        </div>
        <div>
          <FernImage
            className="fern-image--4x3"
            imageUrl={null}
            altText="alt text goes here"
          ></FernImage>
        </div>
      </div>
    </div>
  );
};
