import { useModal } from '@custom_hooks/use_modal';
import { CustomerLeadsService } from '@services/customer_leads_service';
import { SegmentService } from '@services/segment_service';
import {
  createButtonClass,
  FernButton,
} from '@shared_components/fern-button/fern_button';
import { FernModal } from '@shared_components/fern-modal/fern_modal';
import { FernImage, generateSizes } from '@shared_components/fern_image';
import { TextField } from '@shared_components/text-field/text-field';
import { ToasterContext } from '@shared_components/toaster/toaster';
import Image from 'next/image';
import Link from 'next/link';
import React, { FunctionComponent, useContext, useState } from 'react';
import {
  ButterCMSButtonColor,
  ButterCMSButtonSize,
} from '../butter_cms_button_size.type';
import {
  ButterCMSFernishColor,
  getBgColorClass,
  getTextColorClass,
} from '../butter_cms_fernish_color.type';
import styles from './hero_widget.module.css';
import { useBrand } from '@utility_functions/getBrandProps';

export type HeroWidgetProps = {
  headline: string;
  subheadline: string;
  background_color?: ButterCMSFernishColor;
  text_color?: ButterCMSFernishColor;
  image: string;
  button_label: string;
  button_url: string;
  button_size?: ButterCMSButtonSize;
  button_color?: ButterCMSButtonColor;
  ask_for_email?: boolean;
  ask_for_phone?: boolean;
  confirm_modal_headline?: string;
  confirm_modal_copy?: string;
  confirm_modal_button_text?: string;
};

export const HeroWidget: FunctionComponent<HeroWidgetProps> = ({
  headline,
  subheadline,
  background_color,
  text_color,
  image,
  button_label,
  button_url,
  button_size,
  button_color,
  ask_for_email,
  ask_for_phone,
  confirm_modal_headline,
  confirm_modal_copy,
  confirm_modal_button_text,
}) => {
  background_color = background_color || {};
  text_color = text_color || {};
  button_size = button_size || {};
  button_color = button_color || {};

  const { brand } = useBrand();
  const bgColorClass = getBgColorClass(background_color.value, 'bg-sand');
  const textColorClass = getTextColorClass(text_color.value, 'black');
  const btnClass = createButtonClass(button_size.value, button_color.value);

  const showEmailPhoneCapture =
    (ask_for_email || ask_for_phone) &&
    confirm_modal_copy &&
    confirm_modal_button_text;

  const content = (
    <div>
      <h1
        className={[
          'fern-large-header mb-10 small-desktop:mb-30',
          textColorClass,
        ].join(' ')}
      >
        {headline}
      </h1>
      <div
        className={['fern-large-body mb-30', textColorClass].join(' ')}
        dangerouslySetInnerHTML={{ __html: subheadline }}
      ></div>
      {showEmailPhoneCapture ? (
        <EmailPhoneCapture
          button_label={button_label}
          ask_for_email={!!ask_for_email}
          ask_for_phone={!!ask_for_phone}
          confirm_modal_headline={confirm_modal_headline || ''}
          confirm_modal_copy={confirm_modal_copy}
          confirm_modal_button_text={confirm_modal_button_text}
        />
      ) : (
        <Link href={button_url} className={btnClass}>
          {button_label}
        </Link>
      )}
    </div>
  );

  return (
    <div>
      {/* Mobile */}
      <div className="fern-hide-small-desktop-up">
        <div className={[styles.heroBackground, bgColorClass].join(' ')}>
          <div>
            <FernImage
              imageUrl={image}
              altText={`${brand?.brandTitle} Hero Image`}
              className={'fern-image--4x3'}
              nextJSImagePriority
              nextJSImageSizes={generateSizes({
                mobile: 400,
                tablet: 768,
              })}
            />
          </div>
          <div
            className="fern-grid--content"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div className="col-span-full pt-30 pb-60 px-5">{content}</div>
          </div>
        </div>
      </div>

      {/* Desktop */}
      <div className="fern-show-small-desktop-up">
        <div className="relative">
          <div className={[styles.backgroundContainer, bgColorClass].join(' ')}>
            <div></div>
            <div className="relative">
              <Image
                src={image}
                alt={`${brand?.brandTitle} Hero Image`}
                style={{ objectFit: 'cover' }}
                fill
              />
            </div>
          </div>
          <div className="absolute inset-0 flex items-center">
            <div className="fern-grid--content w-[93%]">
              <div className="col-start-1 col-end-5 desktop:col-end-4">
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmailPhoneCapture: FunctionComponent<{
  button_label: string;
  ask_for_email: boolean;
  ask_for_phone: boolean;
  confirm_modal_headline: string;
  confirm_modal_copy: string;
  confirm_modal_button_text: string;
}> = ({
  button_label,
  ask_for_email,
  ask_for_phone,
  confirm_modal_headline,
  confirm_modal_copy,
  confirm_modal_button_text,
}) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailErrorsText, setEmailErrorsText] = useState('');
  const [loading, setLoading] = useState(false);
  const toaster = useContext(ToasterContext);
  const { brand } = useBrand();

  const confirmModal = useModal({
    segmentEventContext: 'Hero Widget Email/Phone Confirmation Modal',
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    setLoading(true);
    setEmailErrorsText('');
    event.preventDefault();

    const contact = { email, phone };
    SegmentService.trackEngagementEvent('User Email Submitted', {
      ...contact,
      companyBrand: brand?.name,
    });
    SegmentService.trackEngagementEvent('Hero Widget Contact Submitted', {
      page_title: document.title,
      companyBrand: brand?.name,
      ...contact,
    });
    CustomerLeadsService.postContact(contact)
      .then(confirmModal.openModal)
      .catch((err: { email: string[] }) => {
        if (err.email && err.email.length > 0) {
          setEmailErrorsText(`${email} ${err.email[0]}`);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-30">
        {ask_for_email && (
          <TextField
            labelText={'Email Address'}
            inputProps={{
              value: email,
              onChange: (event) => setEmail(event.target.value),
              id: 'emailCaptureModalEmail',
              maxLength: 128,
              type: 'email',
              required: true,
            }}
            errorMessageText={emailErrorsText}
          ></TextField>
        )}

        {ask_for_phone && (
          <TextField
            labelText={'Phone Number'}
            inputProps={{
              id: 'emailCaptureModalPhone',
              value: phone,
              onChange: (event) => setPhone(event.target.value),
              maxLength: 128,
              type: 'tel',
            }}
          ></TextField>
        )}

        <div>
          <FernButton
            size="btn"
            color="persimmon"
            title={button_label}
            type="submit"
            onClick={() => {}}
            disabled={loading}
          >
            {button_label}
          </FernButton>
        </div>
      </form>

      <FernModal
        show={confirmModal.open}
        headerText={confirm_modal_headline}
        onClose={confirmModal.closeModal}
        segmentEventContext={confirmModal.segmentEventContext}
        primaryAction={{
          text: confirm_modal_button_text,
          action: confirmModal.closeModal,
        }}
        cancelText=""
      >
        <div
          className="cms-copy"
          dangerouslySetInnerHTML={{ __html: confirm_modal_copy }}
        ></div>
      </FernModal>
    </>
  );
};
