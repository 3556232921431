import { useCarousel } from '@custom_hooks/use_carousel';
import { CarouselIndicators } from '@shared_components/carousel_indicators/carousel_indicators';
import {
  FadingCarousel,
  FadingCarouselSlide,
} from '@shared_components/fading_carousel/fading_carousel';
import { Brand, useBrand } from '@utility_functions/getBrandProps';
import { FunctionComponent } from 'react';

const quotes: Record<Brand, SlideContent[]> = {
  feather: [
    {
      quote: 'High quality but affordable rental designs.',
      title: 'PureWow',
    },
    {
      quote: 'Feather has made furniture rentals cool again.',
      title: 'MyDomaine',
    },
    {
      quote: 'Ease the pain of moving from apartment to apartment.',
      title: 'MindBodyGreen',
    },
  ],
  fernish: [
    {
      quote: "“It's like Rent-the-Runway, but for sofas.”",
      title: 'Fast Company',
    },
    {
      quote:
        '“Upscale furniture without going all-in on expensive long term purchases.”',
      title: 'The New York Times',
    },
    {
      quote: '“The trendiest online furniture-rental company.”',
      title: 'New York Magazine',
    },
  ],
};

export const PressBlockWidget: FunctionComponent<{}> = () => {
  const { brand } = useBrand();
  const content = brand?.name ? quotes[brand.name] : [];
  // Slide State
  const carousel = useCarousel({
    maxLength: content.length,
    segmentEventContext: 'Press Block Widget Carousel',
  });

  return (
    <div>
      {/* MOBILE VERSION */}
      <div className="fern-hide-small-desktop-up">
        <div className="py-60 overflow-hidden small-desktop:py-90">
          <FadingCarousel
            previousSlide={carousel.previous}
            nextSlide={carousel.next}
            segmentEventContext={carousel.segmentEventContext}
          >
            <div className="px-15 relative">
              {content.map((slide, i) => (
                <FadingCarouselSlide show={i === carousel.slideIndex} key={i}>
                  <SlideContent slide={slide}></SlideContent>
                </FadingCarouselSlide>
              ))}
            </div>
          </FadingCarousel>
          <div className="press-block--indicators">
            <CarouselIndicators
              numberOfSlides={content.length}
              activeIndex={carousel.slideIndex}
              setActiveIndex={(i) => carousel.setSlideIndex(i)}
              segmentEventContext={carousel.segmentEventContext}
            ></CarouselIndicators>
          </div>
        </div>
      </div>

      {/* DESKTOP VERSION */}
      <div className="fern-show-small-desktop-up">
        <div className="py-60 overflow-hidden small-desktop:py-90">
          <div className="flex justify-center">
            <div className="flex w-[1351px]">
              {content.map((slide, i) => (
                <SlideContent key={i} slide={slide}></SlideContent>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface SlideContent {
  quote: string;
  title: string;
}

export const SlideContent: FunctionComponent<{ slide: SlideContent }> = ({
  slide,
}) => {
  return (
    <div className="flex-1 mx-30 mb-45 flex flex-col justify-between items-center gap-30">
      <div className="text-center fern-xlarge-body">{slide.quote}</div>
      <div className="fern-label">{slide.title}</div>
    </div>
  );
};
