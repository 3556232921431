import { FernStaticImage } from '@shared_components/fern_image/fern-image';
import { FunctionComponent } from 'react';
import { ServiceAreaZipChecker } from '../../shared_components/service_area_zip_checker';
import deliveryImg from './service_area_widget.jpg';
import deliveryImgTablet from './service_area_widget_tablet.jpg';
import styles from './service_area_widget.module.css';
import { useBrand } from '@utility_functions/getBrandProps';

export const ServiceAreaWidget: FunctionComponent<{}> = () => {
  const { brand } = useBrand();
  const content = {
    title: `${brand?.brandTitle} delivers to:`,
    image: deliveryImg,
    tablet_image: deliveryImgTablet,
    copy: (
      <>
        <div className="mb-15 small-desktop:mb-25 ml-30">
          <ul style={{ listStyleType: 'disc' }}>
            <li>
              <span className="fern-large-body">NYC area</span>
            </li>
            <li>
              <span className="fern-large-body">Southern California</span>
            </li>
            <li>
              <span className="fern-large-body">San Francisco</span>
            </li>
          </ul>
        </div>
        <ServiceAreaZipChecker segmentEventContext="Homepage Widget" />
      </>
    ),
  };

  return (
    <>
      <div className="tablet:fern-grid--content">
        <div className="col-span-full">
          <div
            className={
              'tablet:mb-30 small-desktop:mb-45 gap-30 tablet:grid tablet:grid-cols-6 small-desktop:grid-cols-12 small-desktop:w-full'
            }
          >
            <div className={styles['image']}>
              <div className="fern-show-small-desktop-up">
                <FernStaticImage
                  imageUrl={content.image}
                  altText={content.title}
                  className="fern-image--4x3"
                />
              </div>
              <div className="fern-show-tablet-up fern-hide-small-desktop-up">
                <FernStaticImage
                  imageUrl={content.tablet_image}
                  altText={content.title}
                  className="fern-image--2x3"
                />
              </div>
              <div className="fern-hide-tablet-up">
                <FernStaticImage
                  imageUrl={content.image}
                  altText={content.title}
                  className="fern-image--4x3"
                />
              </div>
            </div>

            <div className={styles['message']}>
              <div className="fern-large-header mb-15">{content.title}</div>
              <div className="fern-large-body">{content.copy}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
