import { DialogOverlay } from '@reach/dialog';
import { SegmentService } from '@services/segment_service';
import React, { FunctionComponent } from 'react';
import { animated, useTransition } from 'react-spring';

// use this only if you need to make a custom modal
// you are probably looking for FernModal
export const FernModalBackground: FunctionComponent<{
  show: boolean;
  onClose: () => void;
  className?: string;
  segmentEventContext: string;
  segmentData?: any;
}> = ({
  show,
  onClose,
  className = '',
  segmentEventContext,
  segmentData,
  children,
}) => {
  function _onClose() {
    onClose();
    SegmentService.trackEngagementEvent(
      `${segmentEventContext} Background Clicked`,
      { ...segmentData }
    );
  }
  const AnimatedDialogOverlay = animated(DialogOverlay);

  const transitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 150 },
  });
  return (
    <div>
      {transitions(
        (styles, item) =>
          item && (
            <AnimatedDialogOverlay
              className={className}
              style={{
                background: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
                opacity: styles.opacity,
              }}
              onDismiss={_onClose}
            >
              <div className="absolute inset-0 flex justify-center items-center">
                {children}
              </div>
            </AnimatedDialogOverlay>
          )
      )}
    </div>
  );
};
