import { useState } from 'react';

export function useCarousel({
  maxLength,
  segmentEventContext,
}: {
  maxLength: number;
  segmentEventContext: string;
}) {
  const [slideIndex, setSlideIndex] = useState(0);

  function previous() {
    if (slideIndex <= 0) {
      return;
    }

    setSlideIndex(slideIndex - 1);
  }

  function next() {
    if (slideIndex >= maxLength - 1) {
      return;
    }

    setSlideIndex(slideIndex + 1);
  }

  const atStart = slideIndex <= 0;

  const atEnd = slideIndex >= maxLength - 1;

  return {
    slideIndex,
    setSlideIndex,
    previous,
    next,
    atStart,
    atEnd,
    segmentEventContext,
  };
}
