import React, { FunctionComponent } from 'react';
import { SwipeEvents } from '../swipe_events';
import { Transition } from 'react-transition-group';

import styles from './fading_carousel.module.css';

export const FadingCarousel: FunctionComponent<{
  nextSlide: () => void;
  previousSlide: () => void;
  segmentEventContext: string;
  className?: string;
}> = ({
  nextSlide,
  previousSlide,
  segmentEventContext,
  className,
  children,
}) => {
  return (
    <SwipeEvents
      onSwipeLeft={nextSlide}
      onSwipeRight={previousSlide}
      segmentContext={segmentEventContext}
    >
      <div className={`${className} relative`}>{children}</div>
    </SwipeEvents>
  );
};

export const FadingCarouselSlide: FunctionComponent<{ show: boolean }> = ({
  show,
  children,
}) => {
  return (
    <Transition in={show} timeout={150}>
      {(state: 'exiting' | 'entering' | 'exited' | 'entered') => (
        <div className={`${styles.slide} ${styles[state]}`}>{children}</div>
      )}
    </Transition>
  );
};
