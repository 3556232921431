export type ButterCMSFernishColor = {
  meta?: {
    id: number;
  };
  value?: string;
};

export function getBgColorClass(
  color: string | undefined,
  fallback: string
): string {
  if (color) {
    return `bg-${color}`;
  } else {
    return fallback;
  }
}

export function getTextColorClass(
  color: string | undefined,
  fallback: string
): string {
  if (color) {
    return `text-${color}`;
  } else {
    return fallback;
  }
}
