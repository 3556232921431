import React, { FunctionComponent } from 'react';

export const KitchenSinkHeader: FunctionComponent<{
  title: string;
}> = ({ title }) => {
  const slug = title.toLowerCase().split(' ').join('_');

  return (
    <div className="mb-15">
      <a
        href={`#${slug}`}
        className="fern-link fern-medium-header"
        id={`${slug}`}
      >
        {title}
      </a>
    </div>
  );
};
