import React, { FunctionComponent } from 'react';
import styles from './value_prop_bar.module.css';

export const ValuePropBar: FunctionComponent = () => {
  return (
    <div className="fern-grid--fullwidth mb-30">
      <div className="fern-show-tablet-up">
        <div className={styles['hp--value-prop-block-container']}>
          <div className={styles['hp--value-prop-block']}>
            <i className="fern-color-icon-truck"></i>
            <div className={styles['hp--value-prop-block-text']}>
              Fast, free delivery & assembly
            </div>
          </div>
          <div className={styles['hp--value-prop-block']}>
            <i className="fern-color-icon-rent-or-buy"></i>
            <div className={styles['hp--value-prop-block-text']}>
              Convenient rent or buy options
            </div>
          </div>
          <div className={styles['hp--value-prop-block']}>
            <i className="fern-color-icon-low-price"></i>
            <div className={styles['hp--value-prop-block-text']}>
              Low upfront costs for all rentals
            </div>
          </div>
          <div className={styles['hp--value-prop-block']}>
            <i className="fern-color-icon-buyout"></i>
            <div className={styles['hp--value-prop-block-text']}>
              Flexibility to buy out rental items later
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div
        className={[
          'fern-hide-tablet-up',
          styles['hp-value-prop-block-mobile'],
        ].join(' ')}
      >
        <div className={styles['hp--container-mobile']}>
          <div className={styles['hp--value-prop-block-container-mobile']}>
            <i className="fern-color-icon-truck"></i>
            <div className={styles['hp--value-prop-block-text']}>
              Fast, free delivery & assembly
            </div>
          </div>
          <div className={styles['hp-value-prop-vertical-hr']}></div>
          <div className={styles['hp--value-prop-block-container-mobile']}>
            <i className="fern-color-icon-rent-or-buy"></i>
            <div className={styles['hp--value-prop-block-text']}>
              Convenient rent or buy options
            </div>
          </div>
          <div className={styles['hp-value-prop-hr']}></div>
          <div className={styles['hp--value-prop-block-container-mobile']}>
            <i className="fern-color-icon-low-price"></i>
            <div className={styles['hp--value-prop-block-text']}>
              Low upfront costs for all rentals
            </div>
          </div>
          <div className={styles['hp-value-prop-vertical-hr']}></div>
          <div className={styles['hp--value-prop-block-container-mobile']}>
            <i className="fern-color-icon-buyout"></i>
            <div className={styles['hp--value-prop-block-text']}>
              Flexibility to buy out rental items later
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
