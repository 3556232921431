import Image from 'next/image';
import React, { FunctionComponent } from 'react';
import heroImg from './hero.jpg';
import styles from './hero.module.css';
import Link from 'next/link';
import { useBrand } from '@utility_functions/getBrandProps';

export const BackupHero: FunctionComponent<{}> = () => {
  const { brand } = useBrand();
  const content = (
    <div>
      <h1 className="fern-large-header text-black mb-10 small-desktop:mb-30">
        Furniture, made light.
      </h1>
      <div className="fern-large-body text-black mb-30">
        Discover hassle-free delivery, free assembly and the flexibility to
        rent, rent-to-own or buy.
      </div>
      <Link href="/n/room_types" className="fern-btn--persimmon">
        Start shopping
      </Link>
    </div>
  );

  return (
    <div className="">
      {/* Mobile */}
      <div className="fern-hide-small-desktop-up">
        <div className={styles.heroBackground}>
          <div>
            <Image
              placeholder="blur"
              src={heroImg}
              alt={`${brand?.brandTitle} Hero Image`}
              layout="responsive"
              priority
            />
          </div>
          <div
            className="fern-grid--content"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div className="col-span-full pt-30 pb-60 px-5">{content}</div>
          </div>
        </div>
      </div>

      {/* Desktop */}
      <div className="fern-show-small-desktop-up">
        <div className="relative">
          <div className={styles.backgroundContainer}>
            <div></div>
            <div className="relative">
              <Image
                src={heroImg}
                alt={`${brand?.brandTitle} Hero Image`}
                style={{ objectFit: 'cover' }}
                fill
              />
            </div>
          </div>
          <div className="absolute inset-0 flex items-center">
            <div className="fern-grid--content w-[93%]">
              <div className="col-start-1 col-end-5 desktop:col-end-4">
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
