import { DialogContent } from '@reach/dialog';
import { SegmentService } from '@services/segment_service';
import { FernButton } from '@shared_components/fern-button/fern_button';
import { Close } from '@shared_components/icons/close';
import classNamesBind from 'classnames/bind';
import React, { FunctionComponent } from 'react';
import styles from './fern_modal.module.css';
import { FernModalBackground } from './fern_modal_background';

const classNames = classNamesBind.bind(styles);

type ButtonAction = {
  text: string;
  action: () => void;
};

export const FernModal: FunctionComponent<{
  show: boolean;
  headerText: string;
  primaryAction?: ButtonAction;
  secondaryAction?: ButtonAction;
  cancelText?: string;
  onClose: () => void;
  segmentEventContext: string;
  segmentData?: any;
  ariaLabel?: string;
  fullscreen?: boolean;
  cantDismiss?: boolean;
}> = ({
  show,
  headerText,
  primaryAction,
  secondaryAction,
  cancelText,
  onClose,
  children,
  segmentEventContext,
  segmentData,
  ariaLabel,
  fullscreen,
  cantDismiss,
}) => {
  // ----------------------------------------
  // helper functions
  // ----------------------------------------
  function _onClose() {
    if (cantDismiss) {
      return;
    }
    onClose();
  }

  function closeIconClicked() {
    _onClose();
    SegmentService.trackEngagementEvent(
      `${segmentEventContext} Close Icon Clicked`,
      {
        ...segmentData,
      }
    );
  }

  function primaryActionClicked() {
    primaryAction?.action();
    SegmentService.trackEngagementEvent(
      `${segmentEventContext} Primary Button Clicked`,
      {
        actionText: primaryAction?.text,
        ...segmentData,
      }
    );
  }

  function secondaryActionClicked() {
    secondaryAction?.action();
    SegmentService.trackEngagementEvent(
      `${segmentEventContext} Secondary Button Clicked`,
      {
        actionText: secondaryAction?.text,
        ...segmentData,
      }
    );
  }

  function cancelButtonClicked() {
    _onClose();
    SegmentService.trackEngagementEvent(
      `${segmentEventContext} Cancel Button Clicked`,
      {
        cancelText: cancelText,
        ...segmentData,
      }
    );
  }

  // ----------------------------------------
  // render helpers
  // ----------------------------------------
  const fullscreenCloseButton = (
    <div className={styles['fullscreen-close-container']}>
      <button
        className="hover:text-papaya focus-visible:outline-none"
        onClick={closeIconClicked}
        aria-label="Close"
      >
        <Close style={{ height: 18, width: 18 }} />
      </button>
    </div>
  );
  const regularCloseButton = (
    <button
      className={`${styles['fern-modal--close']} hover:text-papaya focus-visible:outline-none`}
      onClick={closeIconClicked}
      aria-label="Close"
    >
      <Close style={{ height: 18, width: 18 }} />
    </button>
  );

  if (!ariaLabel && !headerText) {
    throw new Error(
      'The FernModal needs headerText or ariaLabel for accessibility purposes'
    );
  }

  // ----------------------------------------
  // render
  // ----------------------------------------
  return (
    <FernModalBackground
      show={show}
      onClose={_onClose}
      segmentEventContext={segmentEventContext}
      segmentData={segmentData}
    >
      <DialogContent
        className={classNames({ 'fern-modal': true, fullscreen: fullscreen })}
        aria-label={ariaLabel || headerText}
      >
        {cantDismiss ? (
          <></>
        ) : fullscreen ? (
          fullscreenCloseButton
        ) : (
          regularCloseButton
        )}
        {headerText && (
          <div className={['mb-30', fullscreen ? 'mt-30' : ''].join(' ')}>
            <div className="fern-small-header">{headerText}</div>
          </div>
        )}
        <div>{children}</div>
        {primaryAction && (
          <div
            className={classNames({
              'fern-modal--button-container': true,
            })}
          >
            <div
              className={classNames({
                'fern-modal--buttons': true,
                'one-button': !secondaryAction,
              })}
            >
              {primaryAction && (
                <FernButton
                  size="btn"
                  color="persimmon"
                  title={primaryAction.text}
                  onClick={primaryActionClicked}
                >
                  {primaryAction.text}
                </FernButton>
              )}
              {secondaryAction && (
                <FernButton
                  size="secondary-btn"
                  color="persimmon"
                  title={secondaryAction.text}
                  onClick={secondaryActionClicked}
                >
                  {secondaryAction.text}
                </FernButton>
              )}
            </div>
          </div>
        )}
        {cantDismiss ? (
          <></>
        ) : (
          cancelText && (
            <div className={styles['fern-modal--cancel']}>
              <button
                className="fern-link"
                title="Cancel"
                onClick={cancelButtonClicked}
              >
                {cancelText}
              </button>
            </div>
          )
        )}
      </DialogContent>
    </FernModalBackground>
  );
};

FernModal.defaultProps = { cancelText: 'Cancel' };
