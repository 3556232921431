import React, { FunctionComponent } from 'react';
import { SegmentService } from '@services/segment_service';
import { useBrand } from '@utility_functions/getBrandProps';

export const MailtoFernishLink: FunctionComponent<{
  subject?: string;
  mailto?: string;
  segmentEventContext: string;
}> = ({ subject, segmentEventContext, children, mailto }) => {
  const { brand } = useBrand();
  const email = mailto ?? brand?.email;

  let href = `mailto:${email}`;
  if (subject) {
    href = `${href}?subject=${subject}`;
  }

  return (
    <a
      className="fern-link"
      rel="noopener noreferrer"
      target="_blank"
      href={href}
      onClick={() =>
        SegmentService.trackEngagementEvent(
          `${segmentEventContext} CXContactUs Clicked`,
          {}
        )
      }
    >
      {children ? children : email}
    </a>
  );
};
