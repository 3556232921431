import { useModal } from '@custom_hooks/use_modal';
import { FernModal } from '@shared_components/fern-modal/fern_modal';
import { TextField } from '@shared_components/text-field/text-field';
import { FernButton } from '@shared_components/fern-button/fern_button';
import { Wordmark } from '@shared_components/wordmark/wordmark';
import { ToasterContext } from '@shared_components/toaster/toaster';
import { SegmentService } from '@services/segment_service';
import { useRouter } from 'next/router';
import { CustomerLeadsService } from '@services/customer_leads_service';
import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from 'react';
import { BlankErrorBoundary } from '@shared_components/blank_error_boundary';
import { useBrand } from '@utility_functions/getBrandProps';

export const PhoneCaptureModal: FunctionComponent<{}> = () => {
  const modal = useModal({
    segmentEventContext: 'Phone Number Capture Modal',
  });
  const toaster = useContext(ToasterContext);
  const { brand } = useBrand();
  const { query } = useRouter();
  // example url: fernish.com?email_for_phone_capture=test_user@gmail.com
  const email = query.email_for_phone_capture as string;
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (email) {
      modal.openModal();
    }
  }, []);

  const info = {
    headerText: 'Stay in the know with text alerts!',
    formText:
      'Sign up for text notifications to get early access on product launches, exclusive offers, and more.',
    inputPlaceholderText: 'Phone Number',
    inputType: 'tel',
    disclaimerText: (
      <>
        By submitting this form, you agree to receive automated promotional and
        personalized marketing text messages at this number from Fernish.
        Consent is not a condition of any purchase. Msg frequency varies. Msg &
        data rates may apply. Reply HELP for help and STOP to cancel. View{' '}
        <a
          className="fern-link"
          href="/terms-of-use-privacy-policy"
          target="_blank"
        >
          Terms & Privacy
        </a>
      </>
    ),
    btnLabel: 'Sign me up',
    cancelText: 'No, thanks',
    segmentEvent: 'User Phone Number Submitted',
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const contact = { email, phone: inputValue };
    SegmentService.trackEngagementEvent(info.segmentEvent, {
      ...contact,
      companyBrand: brand?.name,
    });
    CustomerLeadsService.postContact(contact)
      .then(() => {
        toaster.addBread({
          type: 'notice',
          message: 'Phone number submitted. Thank you!',
        });
        modal.closeModal();
      })
      .catch((_err) => setErrorMessage('Please enter a valid phone number.'));
  };

  return (
    <BlankErrorBoundary errorContext={modal.segmentEventContext}>
      <FernModal
        show={modal.open}
        onClose={modal.closeModal}
        headerText=""
        cancelText={info.cancelText}
        segmentEventContext={modal.segmentEventContext}
        ariaLabel={info.headerText}
      >
        <div className="text-center my-15">
          <Wordmark />
          <div className="fern-small-header mt-45">{info.headerText}</div>
          <div className="fern-body mt-15">{info.formText}</div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="mb-15 flex flex-col gap-y-15 small-desktop:gap-y-30"
        >
          <TextField
            labelText={info.inputPlaceholderText}
            inputProps={{
              value: inputValue,
              onChange: (e) => setInputValue(e.target.value),
              type: info.inputType,
            }}
            errorMessageText={errorMessage}
          />
          {info.disclaimerText ? (
            <p className="text-left fern-xxsmall-body">{info.disclaimerText}</p>
          ) : (
            <></>
          )}
          <FernButton
            size="btn"
            color="persimmon"
            title="Sign me up"
            type="submit"
            onClick={() => {}}
            className={'text-center'}
          >
            {info.btnLabel}
          </FernButton>
        </form>
      </FernModal>
    </BlankErrorBoundary>
  );
};
