export function generateSizes(options: {
  mobile: number;
  tablet?: number;
  smallDesktop?: number;
  desktop?: number;
  xlDesktop?: number;
}): string {
  const { mobile, tablet, smallDesktop, desktop, xlDesktop } = options;

  const sizes: string[] = [`${mobile}px`];
  if (tablet) {
    sizes.push(`(min-width: 576px) ${tablet}px`);
  }
  if (smallDesktop) {
    sizes.push(`(min-width: 992px) ${smallDesktop}px`);
  }
  if (desktop) {
    sizes.push(`(min-width: 1200px) ${desktop}px`);
  }
  if (xlDesktop) {
    sizes.push(`(min-width: 1600px) ${xlDesktop}px`);
  }

  return sizes.join(', ');
}
