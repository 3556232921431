import React, { Fragment, FunctionComponent } from 'react';
import { SegmentService } from '@services/segment_service';

export type CarouselIndicatorColors = '' | 'daybreak';

export const CarouselIndicators: FunctionComponent<{
  numberOfSlides: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  color?: CarouselIndicatorColors;
  segmentEventContext: string;
}> = ({
  numberOfSlides,
  activeIndex,
  setActiveIndex,
  color = '',
  segmentEventContext,
}) => {
  function handleClick(index: number) {
    SegmentService.trackEngagementEvent(
      `${segmentEventContext} Carousel Indicator Clicked`,
      { index }
    );
    setActiveIndex(index);
  }

  const sizeClass = 'h-[6px] w-[6px]';
  const activeSizeClass = 'h-[8px] w-[8px]';
  const colorClass = color === 'daybreak' ? 'bg-daybreak' : 'bg-light-oak';
  const activeColorClass = 'bg-papaya';
  const commonClasses = 'border-none rounded-full mx-5 transition-colors';

  return numberOfSlides > 1 ? (
    <div className="flex justify-center items-center">
      {Array(numberOfSlides)
        .fill(null)
        .map((_, index) => (
          <Fragment key={index}>
            <div
              className={[
                commonClasses,
                'small-desktop:hidden',
                activeIndex === index ? activeSizeClass : sizeClass,
                activeIndex === index ? activeColorClass : colorClass,
              ].join(' ')}
            ></div>
            <button
              className={[
                commonClasses,
                'hidden small-desktop:block',
                activeIndex === index ? activeSizeClass : sizeClass,
                activeIndex === index ? activeColorClass : colorClass,
              ].join(' ')}
              onClick={() => handleClick(index)}
              aria-label={`Slide ${index + 1}`}
            ></button>
          </Fragment>
        ))}
    </div>
  ) : (
    <></>
  );
};
