import React from 'react';
import { SentryService } from '@services/sentry_service';

interface Props {
  errorContext: string;
}

interface State {
  error: any;
  errorInfo: any;
  errorContext: string;
}

export class BlankErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      errorContext: props.errorContext,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    SentryService.captureError(
      new Error(`Blank Error Boundary > ${this.state.errorContext}`),
      error
    );
  }

  render() {
    if (this.state.error) {
      return <></>;
    }
    return this.props.children;
  }
}
