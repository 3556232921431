import React, { FunctionComponent } from 'react';
import {
  FadingCarousel,
  FadingCarouselSlide,
} from '@shared_components/fading_carousel/fading_carousel';
import { TextField } from '@shared_components/text-field/text-field';
import { useZipChecker } from '@custom_hooks/use_zip_checker';
import { PaddedColorBackground } from '@shared_components/padded_color_background';
import { MailtoFernishLink } from '@shared_components/contact_fernish_components';
import { FernButton } from './fern-button/fern_button';
import { useLocationCaptureModal } from '@custom_hooks/use_location_capture_modal';
import { useBrand } from '@utility_functions/getBrandProps';

export const ServiceAreaZipChecker: FunctionComponent<{
  segmentEventContext: string;
}> = ({ segmentEventContext: _segmentEventContext }) => {
  const { handleZipCodeWithinDeliveryArea, handleZipCodeOutsideDeliveryArea } =
    useLocationCaptureModal();

  const {
    email,
    emailError,
    formState,
    loading,
    handleSubmitEmailLead,
    handleSubmitZipCheck,
    setEmail,
    setZipcode,
    zipcode,
    zipcodeError,
  } = useZipChecker(
    handleZipCodeWithinDeliveryArea,
    handleZipCodeOutsideDeliveryArea
  );
  const { brand } = useBrand();

  const segmentEventContext = `${_segmentEventContext} Zip Checker`;
  const idPrefix = segmentEventContext.replaceAll(' ', '_');

  // ----------------------------------------
  // render
  // ----------------------------------------
  return (
    <PaddedColorBackground className="desktop:p-30 bg-sand">
      <FadingCarousel
        previousSlide={() => {}}
        nextSlide={() => {}}
        segmentEventContext={segmentEventContext}
      >
        <FadingCarouselSlide show={formState === 'unchecked'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div>
              <span className="fern-large-body-bold">Enter your ZIP code</span>{' '}
              to see if we deliver to your area or let us know where you’d like
              us to go next.
            </div>
            <form
              onSubmit={handleSubmitZipCheck}
              className="flex justify-center items-baseline"
            >
              <div className="max-w-[180px] flex-grow mr-15">
                <TextField
                  labelText="Zip code"
                  inputProps={{
                    value: zipcode,
                    onChange: (e) => setZipcode(e.target.value),
                    type: 'number',
                    id: `${idPrefix}Zip`,
                  }}
                  noNumberArrows
                  errorMessageText={zipcodeError}
                />
              </div>
              <button
                type="submit"
                className="fern-small-btn--persimmon"
                disabled={loading}
              >
                Submit
              </button>
            </form>
          </div>
        </FadingCarouselSlide>

        <FadingCarouselSlide show={formState === 'deliverable'}>
          <div className="fern-large-body-bold">
            Great! We deliver to your area.
          </div>
        </FadingCarouselSlide>

        <FadingCarouselSlide show={formState === 'undeliverable'}>
          <div className="fern-large-body-bold mb-15">
            {brand?.brandTitle} doesn't deliver to your area yet.
            <div className="fern-large-body">
              Enter your email and we'll let you know when we're live.
            </div>
          </div>

          <form
            onSubmit={handleSubmitEmailLead}
            className="flex justify-center items-baseline"
          >
            <div className="max-w-[180px] flex-grow mr-15">
              <TextField
                labelText="Your email address"
                inputProps={{
                  value: email,
                  onChange: (e) => setEmail(e.target.value),
                  type: 'email',
                  id: `${idPrefix}Email`,
                }}
                errorMessageText={emailError}
              ></TextField>
            </div>
            <FernButton
              size={'small-btn'}
              color={'persimmon'}
              title={'Submit'}
              disabled={loading}
              type="submit"
            >
              Submit
            </FernButton>
          </form>
        </FadingCarouselSlide>

        <FadingCarouselSlide show={formState === 'lead-collected'}>
          <div className="fern-large-body-bold">
            We'll notify you when we deliver to your area.
          </div>
        </FadingCarouselSlide>

        <FadingCarouselSlide show={formState === 'error'}>
          <div className="fern-large-body-bold">
            Oops! It looks like something went wrong.
            <br />
            Please contact{' '}
            <MailtoFernishLink segmentEventContext={segmentEventContext} /> for
            assistance.
          </div>
        </FadingCarouselSlide>
      </FadingCarousel>
    </PaddedColorBackground>
  );
};
